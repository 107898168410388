import {
  EventHandler,
  MouseEvent,
  MutableRefObject,
  RefObject,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { clearTimeoutIfExists } from 'libs/node';

interface UseDraggableScrollOptions {
  onClick?: (event: MouseEvent) => void;
}

interface DraggableScrollSettings {
  style: {
    overflowX: string;
  };
}

interface UseDraggableScrollReturn {
  hasLeftTransition: boolean;
  hasRightTransition: boolean;
  onLeftTransition: () => void;
  onRightTransition: () => void;
  recalcTransitions: () => void;
  settings: DraggableScrollSettings;
  onMouseDown: (e: MouseEvent | TouchEvent) => void;
}

interface InitialPosition {
  scrollLeft: number;
  x: number;
  lastX: number;
}

const TRANSITION_THRESHOLD = 30;

const getInitialTransitions = (): number[] => [0];

export const useDraggableScroll = <T extends HTMLElement>(
  ref: RefObject<T> | MutableRefObject<T | null>,
  deps: any[] = [],
  options: UseDraggableScrollOptions = {},
): UseDraggableScrollReturn => {
  const initialPosition = useRef<InitialPosition>({ scrollLeft: 0, x: 0, lastX: 0 });
  const isDragging = useRef(false);
  const hasMoveStyles = useRef(false);
  const animationEnd = useRef(true);
  const animationTimer = useRef<NodeJS.Timeout>();

  const transitionsRef = useRef<number[]>(getInitialTransitions());
  const [transitions, setTransitions] = useState<number[]>(getInitialTransitions());
  const [currentTransition, setCurrentTransition] = useState<number>(0);

  const getTransitions = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const newTransitions = getInitialTransitions();
    const containerWidth = ref.current.clientWidth;

    const childNodes = Array.from(ref.current.childNodes) as HTMLElement[];
    for (const node of childNodes) {
      const lastTransition = newTransitions[newTransitions.length - 1] || 0;
      const isInvisible = node.offsetLeft + node.clientWidth > containerWidth + lastTransition;

      if (isInvisible) {
        newTransitions.push(node.offsetLeft);
      }
    }

    transitionsRef.current = newTransitions;
    setTransitions(newTransitions);
  }, [ref]);

  const toggleMoveStyles = useCallback(
    (attach: boolean = false) => {
      if (!ref.current) {
        return;
      }

      if (attach && hasMoveStyles.current) {
        return;
      }

      hasMoveStyles.current = attach;

      if (attach) {
        ref.current.style.userSelect = 'none';
        ref.current.style.pointerEvents = 'none';
      } else {
        ref.current.style.userSelect = '';
        ref.current.style.pointerEvents = '';
        ref.current.style.scrollBehavior = '';
      }
    },
    [ref],
  );

  const makeTransition = useCallback(
    (direction: 'left' | 'right' | 'current', value: number = 0) => {
      if (!ref.current || value === undefined) {
        return;
      }

      ref.current.style.scrollBehavior = 'smooth';
      ref.current.scrollLeft = value;

      clearTimeoutIfExists(animationTimer.current);
      animationTimer.current = setTimeout(
        () => {
          animationEnd.current = true;
          toggleMoveStyles();
        },
        direction === 'current' ? 25 : 300,
      );
    },
    [ref, toggleMoveStyles],
  );

  const transition = useCallback(
    (direction: 'left' | 'right' | 'current') => {
      if (!animationEnd.current || !ref.current) {
        return;
      }

      animationEnd.current = false;

      setCurrentTransition((prevState) => {
        if (direction === 'current') {
          makeTransition(direction, prevState);
          return prevState;
        }

        let newCurrentTransition = prevState;
        const availableTransitions =
          direction === 'left' ? [...transitionsRef.current].reverse() : transitionsRef.current;

        for (const current of availableTransitions) {
          if (direction === 'right' && current > newCurrentTransition) {
            newCurrentTransition = current;
            break;
          }

          if (direction === 'left' && current < newCurrentTransition) {
            newCurrentTransition = current;
            break;
          }
        }

        makeTransition(direction, newCurrentTransition);
        return newCurrentTransition || 0;
      });
    },
    [makeTransition],
  );

  const move = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      if (!ref.current || !animationEnd.current || !isDragging.current) {
        return;
      }

      const newX = 'pageX' in ev ? ev.pageX : ev.touches[0].pageX;
      const dx = newX - initialPosition.current.x;

      if (Math.abs(initialPosition.current.x - newX) >= TRANSITION_THRESHOLD) {
        toggleMoveStyles(true);
      }

      initialPosition.current.lastX = newX;
      ref.current.scrollLeft = initialPosition.current.scrollLeft - dx;
    },
    [ref, toggleMoveStyles],
  );

  const end = useCallback(() => {
    document.removeEventListener('mousemove', move as EventHandler<any>);
    document.removeEventListener('touchmove', move as EventHandler<any>);
    document.removeEventListener('mouseup', end as EventHandler<any>);
    document.removeEventListener('touchend', end as EventHandler<any>);

    if (!ref.current || !animationEnd.current) {
      return;
    }

    isDragging.current = false;
    const { x, lastX } = initialPosition.current;

    if (Math.abs(x - lastX) <= TRANSITION_THRESHOLD) {
      transition('current');
      return;
    }

    if (x !== lastX) {
      transition(x > lastX ? 'right' : 'left');
      return;
    }

    toggleMoveStyles();
  }, [ref, move, transition, toggleMoveStyles]);

  const onMouseDown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!ref.current || !animationEnd.current || isDragging.current) {
        return;
      }

      isDragging.current = true;

      const x = 'pageX' in e ? e.pageX : e.touches[0].pageX;

      initialPosition.current = {
        scrollLeft: ref.current.scrollLeft,
        x,
        lastX: x,
      };

      getTransitions();

      document.addEventListener('mousemove', move as EventHandler<any>);
      document.addEventListener('touchmove', move as EventHandler<any>);
      document.addEventListener('mouseup', end as EventHandler<any>);
      document.addEventListener('touchend', end as EventHandler<any>);
    },
    [ref, getTransitions, move, end],
  );

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const start = (e: Event) => onMouseDown(e as unknown as MouseEvent | TouchEvent);

    element.addEventListener('mousedown', start as EventListener);
    element.addEventListener('touchstart', start as EventListener);

    return () => {
      element.removeEventListener('mousedown', start as EventListener);
      element.removeEventListener('touchstart', start as EventListener);
    };
  }, [ref, onMouseDown]);

  useEffect(() => {
    const element = ref.current;
    if (!element || !options.onClick) {
      return;
    }

    const clickHandler = options.onClick as unknown as EventListener;
    element.addEventListener('click', clickHandler);

    return () => {
      element.removeEventListener('click', clickHandler);
    };
  }, [ref, options.onClick]);

  useEffect(() => {
    const debounce = setTimeout(getTransitions, 1000);
    return () => clearTimeoutIfExists(debounce);
  }, [...deps, getTransitions]);

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(animationTimer.current);
    };
  }, []);

  return {
    hasLeftTransition: Boolean(transitions[transitions.indexOf(currentTransition) - 1] !== undefined),
    hasRightTransition: Boolean(transitions[transitions.indexOf(currentTransition) + 1] !== undefined),
    onLeftTransition: useCallback(() => transition('left'), [transition]),
    onRightTransition: useCallback(() => transition('right'), [transition]),
    recalcTransitions: getTransitions,
    settings: useMemo(
      () => ({
        style: { overflowX: 'hidden' },
      }),
      [],
    ),
    onMouseDown,
  };
};
