/* eslint react-hooks/exhaustive-deps: "warn" */

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { useDraggableScroll } from 'hooks/useDraggableScroll';
import { useEventListener } from 'hooks/useEventListener';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './Slider.module.scss';

interface SliderProps {
  children?: JSX.Element[] | ReactNode | any;
  className?: string;
  heightArrow?: 'top' | 'middle';
  arrowType?: 'primary' | 'secondary';
}

export const Slider = ({ children, className, heightArrow = 'top', arrowType = 'primary' }: SliderProps) => {
  const sliderRef = useRef<null | HTMLDivElement>(null);
  const scrollThrottle = useRef<NodeJS.Timeout>();

  const { onLeftTransition, onRightTransition, hasLeftTransition, hasRightTransition, recalcTransitions } =
    useDraggableScroll(sliderRef, [children]);

  const [mounted, setMounted] = useState(false);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      setShowArrows(sliderRef.current.scrollWidth > sliderRef.current.offsetWidth);
      recalcTransitions();
    }
  }, [mounted, children, recalcTransitions]);

  useEventListener(
    'scroll',
    useCallback(() => {
      if (scrollThrottle.current) {
        clearTimeout(scrollThrottle.current);
      }

      scrollThrottle.current = setTimeout(() => {
        if (sliderRef.current) {
          recalcTransitions();
        }
      }, 100);
    }, [recalcTransitions]),
    sliderRef.current,
  );

  return (
    <div className={cn(styles['slider-wrapper'], className)}>
      {showArrows && (
        <button
          tabIndex={0}
          onClick={onLeftTransition}
          disabled={!hasLeftTransition}
          aria-label="Arrow left"
          className={cn(
            styles['slider-arrow'],
            !hasLeftTransition && styles['hide'],
            styles[`slider-arrow-${heightArrow}`],
            styles[`slider-arrow-type-${arrowType}`],
          )}
        >
          <Icon type="slider-arrow-left" />
        </button>
      )}

      <div ref={sliderRef} className={styles['slider']}>
        {children}
      </div>

      {showArrows && (
        <button
          tabIndex={0}
          onClick={onRightTransition}
          disabled={!hasRightTransition}
          aria-label="Arrow right"
          className={cn(
            styles['slider-arrow'],
            !hasRightTransition && styles['hide'],
            styles[`slider-arrow-${heightArrow}`],
            styles[`slider-arrow-type-${arrowType}`],
          )}
        >
          <Icon type="slider-arrow-right" />
        </button>
      )}
    </div>
  );
};
